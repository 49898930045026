<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.invoice") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.invoiceDesc") }}
        </b-card-sub-title>
      </div>

      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <v-select
          v-model="period"
          :reduce="(val) => val.value"
          :options="periodOptions"
          class="ml-1"
          style="width: 250px"
          :clearable="false"
          @input="loadPeriod(period)"
        />
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import { throws } from "assert";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      userData: store.state.user.userData,

      chartLabels: {},
      chartValuesOpen: [],
      chartValuesClose: [],

      chartTimestamp: "",

      period: "all",
      periodOptions: [],
    };
  },

  async created() {
    await this.loadChartData();
  },

  methods: {
    loadPeriod(period) {
      if (period === "all") {
        this.period = "all";
      } else {
        this.period = period;
      }

      this.loadChartData();
    },

    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/invoices/${this.period}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var data = JSON.parse(response.data.chartInfo); // Json to object

          if (this.period === "all") {
            this.periodOptions = [];
            for (var i = 0; i < data[2].length; i++) {
              this.periodOptions.push({ label: data[2][i], value: data[2][i] });
            }
            this.periodOptions.push({
              label: vm.getColLabel("all"),
              value: "all",
            });
          }

          if (data != null) {
            this.filterData(data, response.data.timestamp);
          }
        });

      this.chart = {
        height: 650,
      };

      this.series = [
        {
          name: vm.getColLabel("closedInvoices"),
          data: this.chartValuesClose,
        },
        {
          name: vm.getColLabel("opneInvoices"),
          data: this.chartValuesOpen,
        },
      ];

      this.chartOptions = {
        colors: ["#2a6592", "#d9d33b"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.1,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 100],
            type: "vertical",
            inverseColors: false,
          },
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return this.currencyValue(val);
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 10,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: this.chartLabels,
        yaxis: {
          categories: this.chartLabels,
          labels: {
            show: true,
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },
      };
    },

    async filterData(data, timestamp) {
      var dates1 = Object.keys(data[0]);
      var values1 = this.fixDecimals(Object.values(data[0]));

      var dates2 = Object.keys(data[1]);
      var values2 = this.fixDecimals(Object.values(data[1]));

      const reduceArr = ({ dates, values }) => {
        const reduced = dates.reduce((a, c, i) => {
          if (typeof a[c] === "undefined") a[c] = 0;
          a[c] += Number(values[i]);
          return a;
        }, {});
        const filteredDates = [...new Set([...dates])];
        const filteredValues = filteredDates.map((date) => reduced[date]);
        return {
          filteredDates,
          filteredValues,
        };
      };

      const mergeDates = ({ dates1, dates2 }) => {
        return [...new Set([...dates1, ...dates2])];
      };

      const sortDates = ({ dates }) => {
        return [...dates].sort((a, b) => {
          const [y1, m1] = a.split("-");
          const [y2, m2] = b.split("-");
          return new Date(y1, m1, 1) - new Date(y2, m2, 1);
        });
      };

      const mapToDates = ({ sortedDates, reducedArr }) => {
        return sortedDates.map((date) => {
          const idx = reducedArr.filteredDates.indexOf(date);
          return idx === -1 ? 0 : reducedArr.filteredValues[idx];
        });
      };

      const mergedDates = mergeDates({ dates1, dates2 });
      const sortedDates = sortDates({ dates: mergedDates });
      const reducedArr1 = reduceArr({ dates: dates1, values: values1 });
      const mapValues1 = mapToDates({ sortedDates, reducedArr: reducedArr1 });
      const reducedArr2 = reduceArr({ dates: dates2, values: values2 });
      const mapValues2 = mapToDates({ sortedDates, reducedArr: reducedArr2 });

      this.chartLabels.categories = sortedDates;
      this.chartValuesOpen = mapValues1;
      this.chartValuesClose = mapValues2;

      this.chartTimestamp = timestamp;
    },

    fixDecimals(value) {
      var data = [];
      for (var i = 0; i < value.length; i++) {
        data.push(value[i].toFixed(2));
      }
      return data;
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
